.footer ul li {
    margin-bottom: 8px; /* Add space between links */
  }
  
  .footer ul li a {
    color: #fedb2f; /* Link color */
    text-decoration: none; /* Remove underline */
  }
  
  .footer ul li a:hover {
    text-decoration: underline; /* Underline on hover */
  }